import axios from "axios";
import demo from "./demo.json";
import prod from "./prod.json";
import jwt from "jsonwebtoken";
import { useHistory } from 'react-router-dom';

// const baseUrl = "http://195.54.162.186:8080/";
//const baseUrl = "http://195.54.162.186:8080/";
//const baseUrl = "https://kkcpsworkflow.com/";
//const baseUrl="http://localhost:8080/"
//const baseUrl = "https://kkcpsworkflow.com/"; //prod
//const baseUrl = "http://localhost:8081/";
const baseUrl = "https://kkcpsworkflow.com/"; //demo
// const isDemo = true; // Set this to true for only demo , prod should be false;
export const isDemo = true; // Set this to true for only demo , prod should be false;

export function LoginRequest(method, body, url) {
  if (url.includes("api") && isDemo) {
    // Replace "/api" with "/apis"
    url = url.replace("api", "apis");
  }
  let config = {
    method: method.toLowerCase(),
    url: url,
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: body,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  };
  return new Promise(function (resolve, reject) {
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(
        //  error
        function (error) {
          if (error === undefined) {
            reject(error);
          }
          reject(error);
        }
      );
  });
}

export function LoadUsersRequest(companyId, userEmail) {
  let mainUrl = "";
  if (isDemo) {
    mainUrl = demo?.find(
      (x) => x.companyId.toString() === companyId.toString()
    );
  } else {
    mainUrl = prod?.find(
      (x) => x.companyId.toString() === companyId.toString()
    );
  }
  if (mainUrl) {
    mainUrl = mainUrl.url;
  }
  let config = {
    method: "GET",
    url: mainUrl,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  };

  return new Promise(function (resolve, reject) {
    if (!mainUrl) {
      resolve([]);
    }
    axios(config)
      .then(function (response) {
        for (const user of response.data.users) {
          if (user.email === userEmail) {
            resolve(user);
            return;
          }
        }
        resolve([]);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function redirectToTokenPage() {
  // Perform the redirection to the token page
  window.location.href = '/#/login'; // Change the URL to your actual token page URL
}
export function request(method, body, url) {
  if (url.includes("api") && isDemo) {
    // Replace "/api" with "/apis"
    url = url.replace("api", "apis");
  }

  var accessToken = null;
  var token = localStorage.getItem("token");

  // if undefined
  if(!token)
  {
    redirectToTokenPage();
  }

  if (token !== "undefined") {
    try {
      accessToken = JSON.parse(token);
      const decodedToken = jwt.decode(accessToken);
      var currentTime = new Date();
      var expirationTime = new Date(decodedToken.exp * 1000); // Convert seconds to milliseconds

      // Calculate the time difference in milliseconds
      var timeDifference = expirationTime - currentTime;
      var timeDifferenceInHours = timeDifference / (60 * 60 * 1000);
  
      if (
        timeDifferenceInHours > 5 ||
        currentTime > expirationTime
      ) {
        redirectToTokenPage();
      }
    } catch (e) {
      redirectToTokenPage();
    }
  }
  let config = {
    method: method.toLowerCase(),
    url: url,
    baseURL: baseUrl,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${accessToken}`,
    },
    data: body,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  };
  return new Promise(function (resolve, reject) {
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(
        //  error
        function (error) {
          if (error === undefined) {
            reject(error);
          }
          reject(error);
        }
      );
  });
}

export function request2(method, body, url) {
  if (url.includes("/api") && isDemo) {
    // Replace "/api" with "/apis"
    url = url.replace("/api", "/apis");
  }

  var bodyFormData = new FormData();
  bodyFormData.append("price", body.price);
  bodyFormData.append("productCategoryId", body.productCategoryId);
  bodyFormData.append("id", body.id);
  bodyFormData.append("productName", body.productName);
  bodyFormData.append("productDescription", body.productDescription);
  bodyFormData.append("file", body.formFile);
  bodyFormData.append("name", body.name);
  bodyFormData.append("description", body.description);
  bodyFormData.append("imageId", body.imageId);
  var accessToken = null;
  var token = localStorage.getItem("access_token");
  if (token != "undefined") {
    accessToken = JSON.parse(token);
  }
  let config = {
    method: method.toLowerCase(),
    url: url,
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
    data: bodyFormData,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  };
  return new Promise(function (resolve, reject) {
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(
        //  error
        function (error) {
          if (error === undefined) {
            reject(error);
          }
          reject(error);
        }
      );
  });
}

export function request3(image) {
  var bodyFormData = new FormData();
  bodyFormData.append("upload_preset", "jokoyoski");
  bodyFormData.append("file", image);
  let config = {
    method: "post",
    url: "https://api.cloudinary.com/v1_1/jokoyoski/image/upload",
    data: bodyFormData,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  };
  return new Promise(function (resolve, reject) {
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(
        //  error
        function (error) {
          if (error === undefined) {
            reject(error);
          }
          reject(error);
        }
      );
  });
}

export default {
  request,
  request2,
  LoginRequest,
  request3,
};
